// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBTV0HiuRKH8TG9LrzgKS_ex0vUj0F7_rY",
  authDomain: "fullylocal-multi-vendor.firebaseapp.com",
  projectId: "fullylocal-multi-vendor",
  storageBucket: "fullylocal-multi-vendor.appspot.com",
  messagingSenderId: "376676634166",
  appId: "1:376676634166:web:c9e42ef3832235d1d7d297",
  measurementId: "G-QE87461W3C"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
