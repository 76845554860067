import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { Button, Input, Spinner } from 'reactstrap'
import { editDocument } from 'store/actions';
import { addDocument } from 'store/actions';

const options = [
    { label: "Bicycle Drill", value: "Bicycle Drill" },
    { label: "Jogging Hero", value: "Jogging Hero" },
    { label: "Healthy Busy", value: "Healthy Busy" },
]

const ProjectModal = ({ siteId, projectId, toggle, edit, add, projectName, imageUrl, tags }) => {
    console.log('tags: ', tags);

    const { sites, btnLoading } = useSelector((state) => state.sites)

    let clientName = '';

    sites.forEach((item) => {
        if (siteId === item.siteId) {
            clientName = item.clientName;
        }
    });

    const [projectPayload, setProjectPayload] = useState({
        userId: '6xBLaLMMMppbwqknIbGu',
        siteId: siteId,
        projectName: '',
        clientName: clientName,
        createdBy: 'Jessica Jones',
        locked: true,
        image: null,
        share: [],
        tags: [],
        status: 'active'
    })
    console.log('projectPayload: ', projectPayload);

    const [error, setError] = useState();

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setProjectPayload(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImage = (e) => {
        let file = e.target.files[0];
        setProjectPayload(prevState => ({
            ...prevState,
            image: { file, previewPic: URL.createObjectURL(file) }
        }));
    };

    const [selectedTags, setSelectedTags] = useState('')

    const handleChangeSelect = (newValue) => {
        setSelectedTags(newValue)
        setProjectPayload((prevState) => ({
            ...prevState,
            tags: newValue.map(option => option.value),
        }));
    };

    const dispatch = useDispatch()

    const handleSubmit = async () => {
        let error = '';
        if (projectPayload.projectName === '') {
            error = 'Project Name is required';
        } else {
            try {
                const res = add ?
                    await dispatch(addDocument({ collectionName: 'pf_projects', payload: projectPayload })) :
                    await dispatch(editDocument({ collectionName: 'pf_projects', id: projectId, payload: projectPayload }));
                if (res?.type === "add/document/fulfilled" || "edit/document/fulfilled") {
                    toggle();
                    setProjectPayload({
                        projectName: '',
                        image: null,
                    });
                } else {
                    console.log('response not fullfilled', res?.payload);
                }
                return;
            } catch (error) {
                console.log('Cannot submit', error);
                return;
            }
        }
        setError(error);
        setTimeout(() => {
            setError('');
        }, 2000);
    };

    useEffect(() => {
        if (edit) {
            setProjectPayload((prevPayload) => ({
                ...prevPayload,
                projectName: projectName,
                image: { previewPic: imageUrl || "" }
            }));
            setSelectedTags(tags?.map(option => ({ label: option, value: option })))
        }
    }, [])

    return (
        <div className=' d-flex flex-column p-3'>
            <h2 className='text-primary font-weight-bolder'>
                {add ? 'New Project' : 'Edit Project'}
            </h2>

            <Input
                className='mb-3'
                type="text"
                placeholder="Enter Project Name"
                name="projectName"
                value={projectPayload.projectName}
                onChange={handleInputs}
                required
            />

            <Select
                className='text-sm mb-3'
                placeholder='Create tags'
                options={options}
                value={selectedTags}
                onChange={handleChangeSelect}
                isMulti
            />

            <div className="d-flex flex-column align-self-start mb-3 border rounded">
                <div>
                    {projectPayload.image?.previewPic &&
                        <img
                            src={projectPayload.image?.previewPic}
                            alt="Picture"
                            height={150}
                            className='w-100'
                        />
                    }
                </div>
                <label
                    className='d-flex align-items-center border-top p-1 m-0'
                    htmlFor="imageInput" role='button'
                >
                    <i className="ni ni-image text-xl mr-2" />
                    Attach Image
                </label>
                <Input
                    className="d-none"
                    id="imageInput"
                    type="file"
                    accept=".jpg , .jpeg ,.png, .svg"
                    onChange={handleImage}
                />
            </div>

            {error && <p className='text-danger text-sm m-0 pl-1 font-weight-bold'>{error}</p>}

            <Button
                color='primary'
                onClick={handleSubmit}
                disabled={btnLoading}
            >
                {btnLoading ? <Spinner color="light" /> : add ? 'CREATE' : 'EDIT'}
            </Button>
        </div>
    )
}

export default ProjectModal