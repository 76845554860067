import { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import "gantt-task-react/dist/index.css";
import { Gantt, ViewMode } from 'gantt-task-react';

const currentDate = new Date();
const allTasks = [
    {
        start: new Date(2024, 1, 1),
        end: new Date(2024, 1, 15),
        name: "Sample Project",
        id: "ProjectSample",
        progress: 25,
        type: "project",

        hideChildren: false
    },
    {
        start: new Date(2024, 1, 1),
        end: new Date(2024, 1, 2),
        name: "Idea",
        id: "Task 0",
        progress: 50,
        type: "task",
        project: "ProjectSample",
    },
    {
        start: new Date(2024, 1, 2),
        end: new Date(2024, 1, 4),
        name: "Research",
        id: "Task 1",
        progress: 25,
        dependencies: ["Task 0"],
        type: "task",
        project: "ProjectSample",
    },
    {
        start: new Date(2024, 1, 4),
        end: new Date(2024, 1, 6),
        name: "Design",
        id: "Task 2",
        progress: 10,
        dependencies: ["Task 1"],
        type: "task",
        project: "ProjectSample"
    },
    {
        start: new Date(2024, 1, 6),
        end: new Date(2024, 1, 8),
        name: "Developing",
        id: "Task 3",
        progress: 80,
        dependencies: ["Task 2"],
        type: "task",
        project: "ProjectSample"
    },
    {
        start: new Date(2024, 1, 8),
        end: new Date(2024, 1, 10),
        name: "Review",
        id: "Task 4",
        type: "task",
        progress: 70,
        dependencies: ["Task 2"],
        project: "ProjectSample"
    },
    {
        start: new Date(2024, 1, 12),
        end: new Date(2024, 1, 12),
        name: "Release",
        id: "Task 6",
        progress: currentDate.getMonth(),
        type: "milestone",
        dependencies: ["Task 4"],
        project: "ProjectSample"
    },
]

const GanttChart = () => {

    const [view, setView] = useState(ViewMode.Day)
    const [tasks, setTasks] = useState(allTasks);
    const [isChecked, setIsChecked] = useState(false)

    let columnWidth = 60;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    } else if (view === ViewMode.Year) {
        columnWidth = 320;
    }
    // EXPAND PROJECT
    const handleExpanderClick = (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    };

    // ADD TASK
    const handleAddTask = () => {
        const currentDate = new Date();

        const newTask = {
            name: "new task",
            type: "task",
            progress: 0,
            id: new Date().getMilliseconds().toString(),
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 28, 0, 0),
            project: "ProjectSample"
        };
        setTasks((e) => [...e, newTask]);
    };

    //DELETE TASK
    const handleTaskDelete = (task) => {
        const conf = window.confirm("Are you sure about " + task.name + " ?");
        if (conf) {
            setTasks(tasks.filter((t) => t.id !== task.id));
        }
        return conf;
    };

    //PROGRESS CHANGE
    const handleProgressChange = async (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    };

    //CHANGE TASK DATE
    const handleTaskDate = (task) => {
        let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project =
                newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map((t) =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        setTasks(newTasks);
    };

    const getStartEndDateForProject = (tasks, projectId) => {
        const projectTasks = tasks.filter((t) => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;

        for (let i = 0; i < projectTasks.length; i++) {
            const task = projectTasks[i];
            if (start.getTime() > task.start.getTime()) {
                start = task.start;
            }
            if (end.getTime() < task.end.getTime()) {
                end = task.end;
            }
        }
        return [start, end];
    };

    return (
        <div>
            <h1 className='text-primary'>Gantt Chart</h1>

            <div className='d-flex justify-content-end mb-3'>

                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column align-items-center mr-3'>
                    <h6 className='text-primary font-weight-bolder mb-1'>Toggle View</h6>
                        <label className="switch">
                            <input type="checkbox" onChange={() => setIsChecked(!isChecked)} />
                            <span className="slider"></span>
                        </label>
                    </div>

                    <Button
                        color='primary'
                        onClick={() => handleAddTask()}>
                        Add
                    </Button>
                </div>
            </div>

            <div 
            className='overflowX'>
                <Gantt
                    tasks={tasks}
                    viewMode={view}
                    onExpanderClick={handleExpanderClick}
                    onDelete={handleTaskDelete}
                    onDateChange={handleTaskDate}
                    onProgressChange={handleProgressChange}
                    columnWidth={columnWidth}
                    listCellWidth={isChecked ? "110px" : ""}
                    fontSize={11}
                />
            </div>

            <div className='d-flex justify-content-end'>
                <ButtonGroup size="sm">
                    <Button
                        color="primary"
                        outline
                        onClick={() => setView(ViewMode.Day)}
                        active={view == ViewMode.Day}
                    >
                        Day
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => setView(ViewMode.Week)}
                        active={view == ViewMode.Week}
                    >
                        Week
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => setView(ViewMode.Month)}
                        active={view == ViewMode.Month}
                    >
                        Month
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => setView(ViewMode.Year)}
                        active={view == ViewMode.Year}
                    >
                        Year
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    )
}
export default GanttChart