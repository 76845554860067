import { configureStore } from '@reduxjs/toolkit'
import SidebarSlice from './slices/SidebarSlice'
import SitesSlice from './slices/SiteSlice'

const store = configureStore({

    reducer: {
        sidebar: SidebarSlice.reducer,
        sites: SitesSlice.reducer
    },
})

export default store 