import { useState } from 'react';
import { Button } from 'reactstrap';
import Creatable from 'react-select/creatable';

const options = [
    { label: "faizan@gmail.com", value: "faizan@gmail.com" },
    { label: "Dawood@gmail.com", value: "Dawood@gmail.com" },
    { label: "Irfan@gmail.com", value: "Irfan@gmail.com" },
];

const isValidEmail = (inputValue) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputValue);
};

const EmailAddress = () => {

    const [selected, setSelected] = useState([]);

    const handleSubmit = () => {
        console.log('email shared', selected)
    }

    const handleInputChange = (inputValue) => {
        if (isValidEmail(inputValue) && !selected.find(option => option.value === inputValue)) {
            setSelected([...selected, { label: inputValue, value: inputValue }]);
        } else {
            alert('Enter valid email address')
        }
    };

    return (
        <div className='d-flex flex-column p-3'>
            <h2 className='text-primary font-weight-bolder'>
                Emails
            </h2>

            <Creatable
                value={selected}
                onChange={setSelected}
                name="emails"
                placeholder="Enter new or select"
                options={options}
                onCreateOption={handleInputChange}
                isMulti
            />
            <Button
                color='primary'
                onClick={handleSubmit}
                className='mt-2'
            >
                Share
            </Button>
        </div>

    )
}
export default EmailAddress