import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Card, Col, DropdownItem, Input, Row } from 'reactstrap'
import { fetchAllProjects } from 'store/actions'
import { fetchFilteredProjects } from 'store/actions'
import { fetchSites } from 'store/actions'

const options = [
    { label: "Bicycle Drill", value: "Bicycle Drill" },
    { label: "Jogging Hero", value: "Jogging Hero" },
    { label: "Healthy Busy", value: "Healthy Busy" },
]

const FiltersBar = ({ siteId, filterFunc }) => {

    const { sites } = useSelector((state) => state.sites);

    const [active, setActive] = useState(null);
    const [selectedTags, setSelectedTags] = useState([])
    const [filterPayload, setFilterPayload] = useState({
        projectName: '',
        status: '',
        tags: [],
        clientName: '',
    })

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setFilterPayload(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeSelect = (newValue) => {
        setSelectedTags(newValue)
        setFilterPayload((prevState) => ({
            ...prevState,
            tags: newValue.map(option => option.value),
        }));
    };

    const handleButton = (number, value) => {
        setActive(number)
        setFilterPayload((prevState) => ({
            ...prevState,
            status: value,
        }));
    };

    const dispatch = useDispatch()

    const handleFilter = () => {
        dispatch(filterFunc({ siteId, ...filterPayload }));
    }

    const handleCancel = () => {
        setFilterPayload({
            projectName: '',
            status: '',
            tags: [],
            clientName: '',
        })
        setSelectedTags([])
        setActive(null)
        window.location.reload()
    }

    useEffect(() => {
        dispatch(fetchSites({
            userId: '6xBLaLMMMppbwqknIbGu',
            field: 'createdAt',
            order: 'desc'
        }));
    }, []);

    return (
        <Card className='d-flex flex-column p-3'>
            <div className='pb-4'>
                <h2>Project Filters</h2>
                <DropdownItem divider />
            </div>

            <div className='pb-4'>
                <h2>Project Name</h2>
                <Input
                    placeholder='Search Project'
                    name='projectName'
                    type='text'
                    value={filterPayload.projectName}
                    onChange={handleInputs}
                />
            </div>

            <div className='pb-4'>
                <h2>Status</h2>
                <Row>
                    <Col className='col-6 pr-1'>
                        <Button
                            className='w-100 ml-0 mb-3 border-success text-xs bg-succcess-light '
                            color='success'
                            outline
                            onClick={() => handleButton(1, 'active')}
                            active={active === 1}
                        >
                            Active
                        </Button>

                        <Button
                            className='w-100 ml-0 border-primary text-xs'
                            color='primary'
                            outline
                            onClick={() => handleButton(2, 'paused')}
                            active={active === 2}
                        >
                            Paused
                        </Button>
                    </Col>

                    <Col className='col-6 pl-1'>
                        <Button
                            className='w-100 ml-0 mb-3 border-warning text-xs'
                            color='warning'
                            outline
                            onClick={() => handleButton(3, 'ongoing')}
                            active={active === 3}
                        >
                            On-going</Button>
                        <Button
                            className='w-100 ml-0 border-danger text-xs'
                            color='danger'
                            outline
                            onClick={() => handleButton(4, 'canceled')}
                            active={active === 4}
                        >
                            Canceled
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className='pb-4'>
                <h2>Tags</h2>

                <Select
                    className='text-sm'
                    placeholder='Create tags'
                    name='tags'
                    type='text'
                    value={selectedTags}
                    options={options}
                    onChange={handleChangeSelect}
                    isMulti
                />
            </div>

            <div className='pb-4'>
                <h2>Client</h2>
                <Input
                    name='clientName'
                    type='select'
                    value={filterPayload.clientName}
                    onChange={handleInputs}
                >
                    <option value='' >Select</option>
                    {sites?.map((item, ind) => {
                        return (
                            <option key={ind} value={item.clientName}>
                                {item.clientName}
                            </option>
                        )
                    })
                    }
                </Input>
            </div>

            <div className='pb-4'>
                <Button onClick={handleFilter}
                    disabled={
                        !(filterPayload.projectName ||
                            filterPayload.clientName ||
                            filterPayload.status ||
                            filterPayload.tags.length > 0
                        )
                    }
                >
                    Done
                </Button>
                <Button onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Card >
    )
}
export default FiltersBar