import { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { Input } from "reactstrap";

const libraries = ["places"]

const AutocompleteInput = ({ sitePayload, setSitePayload, handleInputs }) => {

  const [searchResult, setSearchResult] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    libraries,
  });

  const onLoad = async (autocomplete) => {
    await setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;

      setSitePayload((prevState) => ({
        ...prevState,
        location: formattedAddress
      }));
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <Autocomplete
      options={{ componentRestrictions: { country: ["US"] }, }}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      callbackName={Function.prototype}
    >
      <div>
        <Input
          type="text"
          name="location"
          value={sitePayload.location}
          onChange={handleInputs}
          className=" mb-3 border-light"
          placeholder="Enter Site Location"
        />
      </div>
    </Autocomplete>
  );
};
export default AutocompleteInput;
