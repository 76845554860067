import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Input, Spinner } from 'reactstrap'
import { editDocument } from 'store/actions';
import { addDocument } from 'store/actions';

const MilestoneModal = ({ title, description, milestoneId, add, edit, toggle }) => {
    console.log('milestoneId: ', milestoneId);

    const { btnLoading } = useSelector((state) => state.sites)

    const { siteId, projectId } = useParams();

    const [milestonePayload, setMilestonePayload] = useState({
        userId: '6xBLaLMMMppbwqknIbGu',
        createdBy: 'Jessica Jones',
        projectId: projectId,
        siteId: siteId,
        title: '',
        description: '',
    })

    const [error, setError] = useState();

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setMilestonePayload(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const dispatch = useDispatch()

    const handleSubmit = async () => {
        let error = '';
        if (milestonePayload.title === '') {
            error = 'Title is required';
        } else if (milestonePayload.description === '') {
            error = 'Description is required';
        } else {
            try {
                const res = add ?
                    await dispatch(addDocument({ collectionName: 'pf_milestones', payload: milestonePayload })) :
                    await dispatch(editDocument({ collectionName: 'pf_milestones', id: milestoneId, payload: milestonePayload }));
                if (res?.type === "add/document/fulfilled" || "edit/document/fulfilled") {
                    toggle();
                    setMilestonePayload((prevSitePayload) => ({
                        ...prevSitePayload,
                        title: '',
                        description: '',
                    }));
                } else {
                    console.log('response not fullfilled', res?.payload);
                }
                return;
            } catch (error) {
                console.log('Cannot submit', error);
                return;
            }
        }
        setError(error);
        setTimeout(() => {
            setError('');
        }, 2000);
    };

    useEffect(() => {
        if (edit) {
            setMilestonePayload((prevSitePayload) => ({
                ...prevSitePayload,
                title: title,
                description: description,
            }));
        }
    }, [])

    return (
        <div className=' d-flex flex-column p-3'>
            <h2 className='text-primary font-weight-bolder'>
                New Milestone
            </h2>

            <Input
                className='mb-3'
                type="text"
                placeholder="Enter Title"
                name="title"
                value={milestonePayload.title}
                onChange={handleInputs}
                required
            />

            <Input
                className='mb-3'
                type="text"
                placeholder="Enter description"
                name="description"
                value={milestonePayload.description}
                onChange={handleInputs}
                required
            />
            {error && <p className='text-danger text-sm m-0 pl-1 font-weight-bold'>{error}</p>}

            <Button
                color='primary'
                onClick={handleSubmit}
                disabled={btnLoading}
            >
                {btnLoading ? <Spinner color="light" /> : add ? 'CREATE' : 'EDIT'}
            </Button>
        </div>
    )
}
export default MilestoneModal