import { useEffect, useState } from "react";
import { Button, Col, Container, Input, Modal, Row, Spinner } from "reactstrap";
import FiltersBar from "../components/Sidebar/FiltersBar";
import ProjectCard from "../components/Cards/ProjectCard";
import ProjectModal from "../components/Modals/ProjectModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "store/actions";
import { fetchFilteredSiteProjects } from "store/actions";

const Projects = () => {

    const { projects, isLoading } = useSelector((state) => state.sites)
    console.log('isLoading: ', isLoading);
    console.log('GET/Projects: ', projects);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const { siteId } = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProjects({
            id: siteId,
            field: 'createdAt',
            order: 'desc',
        })).then((res) => {
            console.log(res)
        }
        )
    }, [])

    const handleSelect = (e) => {
        const { value } = e.target;
        const order = e.target.selectedOptions[0].getAttribute('order');
        dispatch(fetchProjects({
            id: siteId,
            field: value,
            order: order,
        }));
    }

    return (
        <Container className="d-block d-lg-flex flex-row justify-content-between" fluid>
            <Col className="col-xl-9 col-lg-8 p-0">
                <div className='d-flex justify-content-end mb-3'>
                    <div className='d-flex flex-column mr-2'>
                        <h6 className='text-primary font-weight-bolder m-0'>Sort By</h6>
                        <Input
                            size='sm'
                            type='select'
                            onChange={handleSelect}
                        >
                            <option value='createdAt' order='desc'>Date</option>
                            <option value='projectName' order='asc'>Name</option>
                        </Input>
                    </div>
                    <Button onClick={toggle} color='primary'>New Project</Button>
                </div>
                {/* {!projects ? */}
                {!projects ?
                    <div className='spinner'>
                        <Spinner className='mx-1' color="primary" type="grow" />
                        <Spinner className='mx-1' color="primary" type="grow" />
                        <Spinner className='mx-1' color="primary" type="grow" />
                    </div>
                    :
                    <Row xl={3} lg={2} md={2} sm={2} xs={1}>
                        {(projects?.length === 0) ?
                            (<h2 className="m-3">you have no active Projects. <br />Please create one!</h2>)
                            :
                            (projects?.map((data) => {
                                return (
                                    <Col className=" my-3" key={data.projectId}>
                                        <ProjectCard data={data} />
                                    </Col>
                                )
                            }))
                        }
                    </Row>
                }
            </Col>

            <Col className="d-block d-lg-flex justify-content-end col-xl-3 col-lg-4 pr-lg-0">
                <FiltersBar siteId={siteId} filterFunc={fetchFilteredSiteProjects} />
            </Col>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ProjectModal siteId={siteId} toggle={toggle} add />
            </Modal>
        </Container >
    )
}
export default Projects