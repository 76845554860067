import { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from 'reactstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteDocument } from 'store/actions';
import { useDispatch } from 'react-redux';
import { duplicateDocument } from 'store/actions';
import MilestoneModal from 'components/Modals/MilestoneModal';
import CardsDropdown from './CardsDropdown';

const MilestoneCard = ({ data }) => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const dispatch = useDispatch()

    const handleDelete = () => {
        dispatch(deleteDocument({ collectionName: 'pf_milestones', id: data.milestoneId }))
    }

    const handleDuplicate = () => {
        dispatch(duplicateDocument({ collectionName: 'pf_milestones', payload: data }))
    }

    return (
        <div className="flip-card">

            <div className='d-flex justify-content-between align-items-center py-2 px-3 border-bottom' >
                <div>
                    <h3 className="text-primary font-weight-bolder m-0">
                        {data.createdBy}
                    </h3>
                    <p className='text-xs m-0'>
                        ({data?.createdAt?.toDate().toDateString()})
                    </p>
                </div>
                <CardsDropdown
                    toggle={toggle}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    m_card
                />
            </div>
            <div
                className='d-flex flex-column m-3'>
                <div className='d-flex flex-column'>
                    <h4>Title:</h4>
                    <p className='text-wrap text-sm'>{data?.title}</p>
                </div>
                <div className='d-flex flex-column'>
                    <h4>Description:</h4>
                    <p className='text-wrap text-sm'>{data?.description}</p>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered>
                <MilestoneModal
                    toggle={toggle}
                    title={data?.title}
                    description={data?.description}
                    milestoneId={data?.milestoneId}
                    edit
                />
            </Modal>
        </div>
    )
}
export default MilestoneCard