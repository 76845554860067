import FiltersBar from "../components/Sidebar/FiltersBar";
import byke_icon from "../assets/img/bicycle.png"
import run_icon from "../assets/img/run.png"
import sneaker_icon from "../assets/img/sneaker.png"
import { Col, Container, Input, InputGroup, InputGroupText, Row, Spinner } from "reactstrap";
import DashboardCard from "../components/Cards/DashboardCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSites } from "store/actions";
import { fetchProjects } from "store/actions";
import ProjectCard from "components/Cards/ProjectCard";
import { fetchAllProjects } from "store/actions";
import { fetchFilteredProjects } from "store/actions";

const Dashboard = () => {

  const { sites, allProjects, isLoading } = useSelector((state) => state.sites)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSites({
      userId: '6xBLaLMMMppbwqknIbGu',
      field: 'createdAt',
      order: 'desc'
    }));

    dispatch(fetchAllProjects({
      field: 'createdAt',
      order: 'desc'
    }))
  }, []);

  const handleSelect = (e) => {
    const { value } = e.target;
    console.log('value: ', value);
    if (value === 'all') {
      dispatch(fetchAllProjects({
        field: 'createdAt',
        order: 'desc'
      }))
    } else {
      dispatch(fetchProjects({
        id: value,
        field: 'createdAt',
        order: 'desc',
      }))
    }
  }

  return (
    <Container className="d-block d-lg-flex flex-row justify-content-between" fluid>
      <Col className="col-xl-9 col-lg-8 p-0">
        <Col className="p-0 col-5 col-md-3">
            <Input
              type='select'
              size='sm'
              onChange={handleSelect}
            >
              <option value='all'>All Projects</option>

              {sites?.map((data, index) => {
                return (
                  <option key={index} value={data.siteId}>
                    {data.siteName}
                  </option>
                )
              })
              }
            </Input>
        </Col>

        {/* <Row xl={3} lg={2} md={2} sm={2} xs={1}>
          <Col className=" my-3">
            <DashboardCard title='Bicycle Drill' subtitle='36 km / weeks' img={byke_icon} />
          </Col>

          <Col className=" my-3">
            <DashboardCard title='Jogging Hero' subtitle='36 km / month' img={run_icon} />
          </Col>

          <Col className=" my-3">
            <DashboardCard title='Healthy Busy' subtitle='3600 steps / weeks' img={sneaker_icon} />
          </Col>
        </Row> */}

        {!allProjects || !sites ?
          <div className='spinner'>
            <Spinner className='mx-1' color="primary" type="grow" />
            <Spinner className='mx-1' color="primary" type="grow" />
            <Spinner className='mx-1' color="primary" type="grow" />
          </div>
          :
          <Row xl={3} lg={2} md={2} sm={2} xs={1}>
            {(allProjects?.length === 0) ?
              <h2 className="m-3">No active Projects.</h2>
              :
              allProjects?.map((data) => {
                return (
                  <Col className=" my-3" key={data.projectId}>
                    <ProjectCard data={data} />
                  </Col>
                )
              })
            }
          </Row>
        }
      </Col>

      <Col className="d-block d-lg-flex justify-content-end col-xl-3 col-lg-4 pr-lg-0">
        <FiltersBar filterFunc={fetchFilteredProjects} />
      </Col>

    </Container>

  );
};
export default Dashboard;