import { Card, Input, Button } from 'reactstrap';
import avatar1 from '../../assets/img/theme/team-3-800x800.jpg'
import avatar2 from '../../assets/img/theme/team-4-800x800.jpg'
import attachment from '../../assets/img/attachment.png'
import { useState } from 'react';
const DiscussionsTab = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedFile(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    return (
            <div>
                <h1 className='text-primary'>Task Lists</h1>

                <Card className='p-0' body>

                    {/* --------------------------ChatHeader------------------------------------- */}
                    <div className='d-flex flex-row align-items-center p-2 rounded bg-primary'>
                        <img
                            className='rounded-circle mr-2'
                            src={avatar2}
                            alt='avatar'
                            width={100}
                        />
                        <div>
                            <h1 className='text-white'>Rose Mary</h1>
                            <h3 className='text-light'>Customer Service Representative</h3>
                        </div>
                    </div>

                    <br />

                    <div className='m-2'>

                        {/* --------------------------SenderText------------------------------------- */}
                        <div className='d-flex flex-row align-items-center justify-content-end my-2'>
                            <Card className='border-light'>
                            <div className='border-bottom px-3 py-2 bg-primary rounded-top'>
                                    <h3 className='m-0 text-white'>Jessica Jones</h3>
                                </div>

                                <div className='m-3'>
                                    <p className='m-0'>hi! I need some help.</p>
                                </div>

                                <div className='d-flex justify-content-end border-top px-1'>
                                    <h5 className='m-0 text-muted'>2:30pm</h5>
                                </div>
                            </Card>
                            <img
                                className='rounded-circle ml-2'
                                src={avatar1}
                                alt='avatar'
                                width={50}
                            />
                        </div>

                        {/* --------------------------ReceiverText------------------------------------- */}
                        <div className='d-flex flex-row align-items-center justify-content-start my-2'>
                        <img
                                className='rounded-circle mr-2'
                                src={avatar2}
                                alt='avatar'
                                width={50}
                            />
                            <Card className='border-light'>  
                                <div className='border-bottom px-3 py-2 bg-primary rounded-top'>
                                    <h3 className='m-0 text-white'>Rose Mary</h3>
                                </div>

                                <div className='m-3'>
                                    <p className='m-0'>
                                        Hi! How can i help you.
                                    </p>
                                </div>

                                <div className='d-flex justify-content-end border-top px-1'>
                                    <h5 className='m-0 text-muted'>2:31pm</h5>
                                </div>
                            </Card>
                        </div>

                        {/* --------------------------SenderText------------------------------------- */}
                        <div className='d-flex flex-row align-items-center justify-content-end my-2'>
                            <Card className='border-light'>
                            <div className='border-bottom px-3 py-2 bg-primary rounded-top'>
                                    <h3 className='m-0 text-white'>Jessica Jones</h3>
                                </div>

                                <div className='m-3'>
                                    <p className='m-0'>
                                        I need some information regarding privacy policy.
                                    </p>
                                </div>

                                <div className='d-flex justify-content-end border-top px-1'>
                                    <h5 className='m-0 text-muted'>2:35pm</h5>
                                </div>
                            </Card>
                            <img
                                className='rounded-circle ml-2'
                                src={avatar1}
                                alt='avatar'
                                width={50}
                            />
                        </div>


                        {/* --------------------------ReceiverText------------------------------------- */}
                        <div className='d-flex flex-row align-items-center justify-content-start my-2'>
                        <img
                                className='rounded-circle mr-2'
                                src={avatar2}
                                alt='avatar'
                                width={50}
                            />
                            <Card className='border-light'>  
                            <div className='border-bottom px-3 py-2 bg-primary rounded-top'>
                                    <h3 className='m-0 text-white'>Rose Mary</h3>
                                </div>

                                <div className='m-3'>
                                    <p className='m-0'>
                                        Wait! Let me connect to you to our supervisor.
                                    </p>
                                </div>

                                <div className='d-flex justify-content-end border-top px-1'>
                                    <h5 className='m-0 text-muted'>2:31pm</h5>
                                </div>
                            </Card>
                        </div>

                        <br />

                        <Input
                            className='p-2'
                            type="textarea"
                            placeholder='Type your message' />

                        <div className='d-flex justify-content-end align-items-center my-2'>
                            <div>
                                {selectedFile && (
                                    <img
                                        src={selectedFile}
                                        alt="Preview"
                                        height={40}
                                    />
                                )}
                                <label htmlFor="fileInput">
                                    <img
                                        className='mx-3'
                                        role='button'
                                        src={attachment}
                                        alt="Upload"
                                        width={30}
                                    />
                                </label>
                                <input
                                    className='d-none'
                                    type="file"
                                    id="fileInput"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <Button color='primary'>
                                Send
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
    )
}
export default DiscussionsTab