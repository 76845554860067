import React, { useEffect, useRef } from 'react';
import { widgets } from 'react-trello';
import * as THREE from 'three';

export default function ThreeD() {
    const refContainer = useRef(null);
    // console.log('refContainer: ', refContainer);
    // console.log('refContainerC: ', refContainer.current);

    useEffect(() => {

        let camera, scene, renderer;
        let plane;
        let pointer, raycaster, isShiftDown = false;
        let rollOverMesh, rollOverMaterial;
        let cubeGeo, cubeMaterial;
        const objects = [];

        function init() {
            camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
            camera.position.set(500, 800, 1300);
            camera.lookAt(0, 0, 0);

            scene = new THREE.Scene();
            scene.background = new THREE.Color(0xf0f0f0);

            // roll-over helpers
            const rollOverGeo = new THREE.BoxGeometry(50, 50, 50);
            rollOverMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, transparent: true });
            rollOverMesh = new THREE.Mesh(rollOverGeo, rollOverMaterial);
            scene.add(rollOverMesh);

            // cubes
            // const map = new THREE.TextureLoader().load('textures/square-outline-textured.png');
            // map.colorSpace = THREE.SRGBColorSpace;
            cubeGeo = new THREE.BoxGeometry(50, 50, 50);
            cubeMaterial = new THREE.MeshLambertMaterial({ color: '#7163ba' });

            // grid
            const gridHelper = new THREE.GridHelper(1000, 20);
            scene.add(gridHelper);

            raycaster = new THREE.Raycaster();
            pointer = new THREE.Vector2();

            const geometry = new THREE.PlaneGeometry(1000, 1000);
            geometry.rotateX(-Math.PI / 2);

            plane = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ visible: false }));
            scene.add(plane);

            objects.push(plane);

            // lights
            const ambientLight = new THREE.AmbientLight(0x606060, 3);
            scene.add(ambientLight);

            const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
            directionalLight.position.set(1, 0.75, 0.5).normalize();
            scene.add(directionalLight);

            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setPixelRatio(window.devicePixelRatio);
            // adjust it according to screen size

            renderer.setSize(1745, 800);
            refContainer.current && refContainer.current.appendChild(renderer.domElement);

            document.addEventListener('pointermove', onPointerMove);
            document.addEventListener('pointerdown', onPointerDown);
            document.addEventListener('keydown', onDocumentKeyDown);
            document.addEventListener('keyup', onDocumentKeyUp);

            window.addEventListener('resize', onWindowResize);
        }

        function onWindowResize() {
            const width = refContainer.current.clientWidth;
            const height = refContainer.current.clientHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);

            render();
        }

        function onPointerMove(event) {
            pointer.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
            raycaster.setFromCamera(pointer, camera);
            const intersects = raycaster.intersectObjects(objects, false);
            if (intersects.length > 0) {
                const intersect = intersects[0];
                rollOverMesh.position.copy(intersect.point).add(intersect.face.normal);
                rollOverMesh.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);
                render();
            }
        }

        function onPointerDown(event) {
            pointer.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
            raycaster.setFromCamera(pointer, camera);
            const intersects = raycaster.intersectObjects(objects, false);
            if (intersects.length > 0) {
                const intersect = intersects[0];
                if (isShiftDown) {
                    if (intersect.object !== plane) {
                        scene.remove(intersect.object);
                        objects.splice(objects.indexOf(intersect.object), 1);
                    }
                } else {
                    const voxel = new THREE.Mesh(cubeGeo, cubeMaterial);
                    voxel.position.copy(intersect.point).add(intersect.face.normal);
                    voxel.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);
                    scene.add(voxel);
                    objects.push(voxel);
                }
                render();
            }
        }

        function onDocumentKeyDown(event) {
            switch (event.keyCode) {
                case 16:
                    isShiftDown = true;
                    break;
            }
        }

        function onDocumentKeyUp(event) {
            switch (event.keyCode) {
                case 16:
                    isShiftDown = false;
                    break;
            }
        }

        function render() {
            renderer.render(scene, camera);
        }

        init();
        render();

        return () => {
            document.removeEventListener('pointermove', onPointerMove);
            document.removeEventListener('pointerdown', onPointerDown);
            document.removeEventListener('keydown', onDocumentKeyDown);
            document.removeEventListener('keyup', onDocumentKeyUp);
            window.removeEventListener('resize', onWindowResize);
            // Clean up three.js scene
            // scene.dispose();
            renderer.dispose();
        };
    }, []);

    return <div ref={refContainer}></div>;
}