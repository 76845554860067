import { useState } from 'react';
import { Modal } from 'reactstrap'
import { useHistory } from 'react-router-dom';
import { deleteDocument } from 'store/actions';
import { useDispatch } from 'react-redux';
import SiteModal from 'components/Modals/SiteModal';
import { duplicateDocument } from 'store/actions';
import CardsDropdown from './CardsDropdown';

const SiteCard = ({ data, flipCard }) => {

    const [duplicatePayload, setDuplicatePayload] = useState({
        userId: data.userId,
        siteName: data.siteName,
        location: data.location,
        clientName: data.clientName,
        createdBy: data.createdBy,
        imageUrl: data.imageUrl || null,
        siteId: '',
        createdAt: '',
    });

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const history = useHistory()
    const dispatch = useDispatch()

    const handleNavigate = (siteId) => {
        history.push(`/admin/projects/${siteId}`)
    }

    const handleDelete = () => {
        dispatch(deleteDocument({
            collectionName: 'pf_sites',
            id: data.siteId
        }))
    }

    const handleDuplicate = () => {
        dispatch(duplicateDocument({
            collectionName: 'pf_sites',
            payload: duplicatePayload
        }))
    }

    return (
        <div className="flip-card">
            <div className='flip-card-top' >
                <div className='d-flex flex-column'>
                    <h2 className="text-white font-weight-bolder mb-0">
                        {data.siteName}
                    </h2>
                    <h6 className='m-0 text-white'>
                        {data?.createdAt?.toDate().toDateString()}
                    </h6>
                </div>

                <CardsDropdown
                    siteId={data.siteId}
                    toggle={toggle}
                    handleDuplicate={handleDuplicate}
                    handleNavigate={handleNavigate}
                    handleDelete={handleDelete}
                    s_card
                />
            </div>

            <div className={flipCard ? "flip-card-inner" : "flip-card-inner-toggle"}>
                <div className="flip-card-front">
                    {data.imageUrl ?
                        <img
                            src={data.imageUrl}
                            alt="cardImage"
                        /> :
                        <span className='d-flex align-items-center justify-content-center h-100 '>
                            <i className="ni ni-image text-xl mr-2" />
                            <h2 className='m-0'>No Image Attached</h2>
                        </span>
                    }
                </div>

                <div className="flip-card-back">
                    <div className='d-flex flex-column h-100'>
                        <div className='row border-bottom border-light no-gutters' >
                            <div className='col p-2 d-flex flex-column border-right border-light'>
                                <h5 className='m-0 font-weight-bolder'>Client Name:</h5>
                                <p className='m-0 font-weight-normal'>{data?.clientName}</p>
                            </div>
                            <div className='col p-2 d-flex flex-column'>
                                <h5 className='m-0 font-weight-bolder'>Created By:</h5>
                                <p className='m-0 font-weight-normal'>{data?.createdBy}</p>
                            </div>
                        </div>

                        <div className='d-flex flex-column p-2 my-auto'>
                            <h5 className='m-0 font-weight-bolder'>Location:</h5>
                            <p className='m-0 font-weight-normal'>{data?.location}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered>
                <SiteModal
                    toggle={toggle}
                    siteId={data.siteId}
                    siteName={data.siteName}
                    clientName={data.clientName}
                    imageUrl={data.imageUrl}
                    location={data.location}
                    edit
                />
            </Modal>
        </div>
    )
}
export default SiteCard