import Board, { Container } from 'react-trello'

const data = {
    lanes: [
        {
            id: 'lane1',
            title: 'Planned Tasks',
            cards: [
                { id: 'Card1', title: 'Meeting', description: 'Briefing on new project', label: '30 mins', },
                { id: 'Card2', title: 'Pay Salaries', description: 'Transfer via companies acount', label: '5 mins', metadata: { sha: 'be312a1' } }
            ]

        },
        {
            id: 'lane2',
            title: 'Work in progress',
            cards: [
                { id: 'Card1', title: 'Project Fully Local', description: 'Made plans and layouts', label: '1 month' }
            ]
        },
        {
            id: 'lane3',
            title: 'Completed',
            cards: [
                { id: 'Card1', title: 'Buy Hosting', description: 'Done', label: '7 days' }
            ]
        }
    ],
}

const settings = {
    editable: true,
    canAddLanes: true,
    editLaneTitle: true,
    draggable: true,
}

const TaskListsTab = () => {
    return (
        <div>
            <h1 className='text-primary'>Task Lists</h1>
            <Container>
                <Board className='bg-white border' data={data} {...settings} />
            </Container>
        </div>
    )
}
export default TaskListsTab