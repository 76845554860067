/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Profile from 'views/Profile.js';
import Register from 'views/auth/Register.js';
import Login from 'views/auth/Login.js';
import Tables from 'views/Tables.js';
import Icons from 'views/Icons.js';
import Dashboard from 'views/Dashboard';
import Projects from 'views/Projects';
import Charts from 'views/Charts';
import Sites from 'views/Sites';
import ProjectDetail from './components/Project/ProjectDetail';

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2',
		component: Dashboard,
		layout: '/admin',
	},
	{
		path: '/sites',
		name: 'Sites',
		icon: 'ni ni-archive-2',
		component: Sites,
		layout: '/admin',
	},
	{
		path: '/projects/:siteId',
		name: 'Projects',
		icon: 'ni ni-briefcase-24',
		component: Projects,
		layout: '/admin',
		isMenu: false,

	},
	{
		path: '/projects/:siteId/:projectId',
		name: 'Project Detail',
		component: ProjectDetail,
		layout: '/main',
		isMenu: false,
	},
	// {
	// 	path: '/icons',
	// 	name: 'Icons',
	// 	icon: 'ni ni-planet',
	// 	component: Icons,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/user-profile',
	// 	name: 'User Profile',
	// 	icon: 'ni ni-single-02',
	// 	component: Profile,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/tables',
	// 	name: 'Tables',
	// 	icon: 'ni ni-bullet-list-67',
	// 	component: Tables,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/charts',
	// 	name: 'Charts',
	// 	icon: 'ni ni-bullet-list-67',
	// 	component: Charts,
	// 	layout: '/admin',
	// },
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25',
		component: Login,
		layout: '/auth',
		isMenu: false,
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08',
		component: Register,
		layout: '/auth',
		isMenu: false
	},
];
export default routes;
