import React from "react"
import { Card, Progress, UncontrolledTooltip } from "reactstrap"
import user1 from '../../assets/img/theme/team-1-800x800.jpg'
import user2 from '../../assets/img/theme/team-2-800x800.jpg'
import user3 from '../../assets/img/theme/team-3-800x800.jpg'
import user4 from '../../assets/img/theme/team-4-800x800.jpg'

const icons = [
    { name: 'Overview', icon: "avatar avatar-sm ni ni-tv-2 text-info" },
    { name: 'TaskLists', icon: "avatar avatar-sm fa fa-check-square text-danger" },
    { name: 'Discussions', icon: "avatar avatar-sm fa fa-comments text-success" },
    { name: 'Milestones', icon: "avatar avatar-sm fa fa-flag text-primary" },
    { name: 'Files', icon: "avatar avatar-sm fa fa-folder-open text-yellow" },
    { name: 'GanttChart', icon: "avatar avatar-sm fa fa-bars text-info" },
]
const shared= [
    { name: 'Mike', img: user1 },
    { name: 'Kaila', img: user2 },
    { name: 'Youming', img: user3 },
    { name: 'Tanya', img: user4 },
]

const DetailsBox = ({ share }) => {
    return (
        <Card className=' d-flex justify-content-center align-items-center p-2'>

            {/* ------------------ICONS--------------------- */}
            <div className='mb-4'>
                {icons.map((data, index) => {
                    return (
                        <React.Fragment key={index}>
                            <a
                                className="avatar avatar-sm"
                                id={data.name}
                            >
                                <i className={data.icon} />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target={data.name}
                            >
                                {data.name}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )
                })
                }
            </div>

            {/* -------------PROGRESS BAR------------------- */}
            <Progress
                className="progressbar"
                color="info"
                value={50}
            />

            {/* ---------------AVATAR GROUP----------------- */}
            <div className="avatar-group">
                {shared.map((data, index) => {
                    return (
                        <React.Fragment key={index}>
                            <a
                                className="avatar avatar-sm "
                                id={data.name}
                            >
                                <img
                                    alt="icons"
                                    className="rounded-circle"
                                    src={data.img}
                                />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target={data.name}
                            >
                                {data.name}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )
                })
                }
            </div>
        </Card>
    )
}
export default DetailsBox