import { createSlice } from '@reduxjs/toolkit';
import { fetchProjects } from 'store/actions';
import { deleteDocument } from 'store/actions';
import { fetchSites } from 'store/actions';
import { fetchFilteredProjects } from 'store/actions';
import { projectPrivacy } from 'store/actions';
import { fetchFilteredSiteProjects } from 'store/actions';
import { fetchAllProjects } from 'store/actions';
import { duplicateDocument } from 'store/actions';
import { editDocument } from 'store/actions';
import { fetchSingleProject } from 'store/actions';
import { addDocument } from 'store/actions';

const SitesSlice = createSlice({

  name: 'site',
  initialState: {
    sites: null,
    projects: null,
    allProjects: null,
    milestones: null,
    singleProject: null,
    isLoading: false,
    btnLoading: false,
  },
  reducers: {
    handleSites: (state, action) => {
      state.sites = action.payload;
    },

    handleProjects: (state, action) => {
      state.projects = action.payload;
    },

    handleAllProjects: (state, action) => {
      state.allProjects = action.payload;
    },

    handleMilestones: (state, action) => {
      state.milestones = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      //Fetch Sites
      .addCase(fetchSites.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSites.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSites.rejected, (state) => {
        state.isLoading = false
      })

      //Fetch Selected Site Projects
      .addCase(fetchProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProjects.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.isLoading = false
      })

      //Fetch All Project
      .addCase(fetchAllProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllProjects.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchAllProjects.rejected, (state) => {
        state.isLoading = false
      })

      //Fetch Single Project
      .addCase(fetchSingleProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSingleProject.fulfilled, (state, action) => {
        state.singleProject = action.payload
        state.isLoading = false
      })
      .addCase(fetchSingleProject.rejected, (state) => {
        state.isLoading = false
      })

      //Add
      .addCase(addDocument.pending, (state) => {
        state.btnLoading = true
      })
      .addCase(addDocument.fulfilled, (state) => {
        state.btnLoading = false
      })
      .addCase(addDocument.rejected, (state) => {
        state.btnLoading = false
      })

      //Edit
      .addCase(editDocument.pending, (state) => {
        state.btnLoading = true
      })
      .addCase(editDocument.fulfilled, (state) => {
        state.btnLoading = false
      })
      .addCase(editDocument.rejected, (state) => {
        state.btnLoading = false
      })

      //delete
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteDocument.rejected, (state) => {
        state.isLoading = false
      })

      //duplicate
      .addCase(duplicateDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(duplicateDocument.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(duplicateDocument.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Filtered Projects
      .addCase(fetchFilteredProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFilteredProjects.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFilteredProjects.rejected, (state) => {
        state.isLoading = false
      })

      //Fetch Filtered Site Projects
      .addCase(fetchFilteredSiteProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFilteredSiteProjects.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFilteredSiteProjects.rejected, (state) => {
        state.isLoading = false
      })

      //Privacy Projects
      .addCase(projectPrivacy.pending, (state) => {
        state.isLoading = true
      })
      .addCase(projectPrivacy.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(projectPrivacy.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const { handleSites, handleProjects, handleMilestones, handleAllProjects } = SitesSlice.actions;
export default SitesSlice