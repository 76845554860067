import { useEffect, useState } from 'react';
import SiteCard from '../components/Cards/SiteCard';
import { Button, Col, Container, Modal, Row, Input, Spinner, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSites } from 'store/actions';
import SiteModal from 'components/Modals/SiteModal';
import { fetchProjects } from 'store/actions';
import { handleProjects } from 'store/slices/SiteSlice';

const Sites = () => {

    const { sites, isLoading } = useSelector((state) => state.sites)
    console.log('GET/Sites: ', sites);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [flipCard, setFlipCard] = useState(false);
    const flip = () => setFlipCard(!flipCard);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchSites({
            userId: '6xBLaLMMMppbwqknIbGu',
            field: 'createdAt',
            order: 'desc'
        }));

        dispatch((handleProjects(null)))
    }, []);

    const handleSelect = (e) => {
        const { value } = e.target;
        const order = e.target.selectedOptions[0].getAttribute('order');
        dispatch(fetchSites({
            userId: '6xBLaLMMMppbwqknIbGu',
            field: value,
            order: order
        }));
    }

    return (
        <Container fluid className='d-flex flex-column'>

            {/* ---------------------TopBAR-------------------- */}
            <div className='d-flex justify-content-end mb-3'>
                <div className='d-flex align-items-center'>

                    {/* ---------------------SWITCH-------------------- */}
                    <div className='d-flex flex-column align-items-center mr-2'>
                        <h6 className='text-primary font-weight-bolder mb-1'>Toggle View</h6>
                        <label className="switch">
                            <input type="checkbox" onChange={flip} />
                            <span className="slider"></span>
                        </label>
                    </div>

                    {/* ---------------------SORT BY-------------------- */}
                    <div className='d-flex flex-column mr-2'>
                        <h6 className='text-primary font-weight-bolder m-0'>Sort By</h6>
                        <Input
                            size='sm'
                            type='select'
                            onChange={handleSelect}
                        >
                            <option value='createdAt' order='desc'>Date</option>
                            <option value='siteName' order='asc'>Name</option>
                            <option value='clientName' order='asc'>Client</option>
                        </Input>
                    </div>

                    {/* ---------------------BUTTON-------------------- */}
                    <Button
                        onClick={toggle}
                        color='primary'>
                        New Site
                    </Button>

                </div>
            </div>

            {/* ---------------------CARDS-------------------- */}
            {!sites ?
                < div className='spinner'>
                    <Spinner className='mx-1' color="primary" type="grow" />
                    <Spinner className='mx-1' color="primary" type="grow" />
                    <Spinner className='mx-1' color="primary" type="grow" />
                </div>
                :
                <Row xl={4} lg={3} md={2} sm={1} xs={1}>
                    {(sites?.length === 0) ?
                        <h2 className="m-3">you are no active Sites. <br />Please create one!</h2>
                        :
                        sites?.map((data) => {
                            return (
                                <Col className=" my-3" key={data.siteId}>
                                    <SiteCard flipCard={flipCard} data={data} />
                                </Col>
                            )
                        })
                    }
                </Row>
            }
            {/* ---------------------MODAL-------------------- */}
            <Modal isOpen={modal} toggle={toggle} centered className='mdl'>
                <SiteModal toggle={toggle} add />
            </Modal>

        </Container >
    )
}
export default Sites