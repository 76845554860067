import { Link } from "react-router-dom";
import avatar from "../../assets/img/theme/team-3-800x800.jpg"
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  NavLink,
} from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminNavbar = (props) => {

  const history = useHistory()

  return (
    <>
      <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main">
        {
          props.match.path == '/main' ?
            <Button
              color="primary"
              onClick={() => history.push('/admin/sites')}
            >
              BACK
            </Button>
            : null
        }
        <Container className="justify-content-end" fluid>
          {/* <Link
            className="h4 mb-0 font-weight-bolder text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            <h1 className="text-primary">{props.brandText}</h1>
          </Link> */}

          {/* <Form className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={avatar}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      Jessica Jones
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="fas fa-sitemap mr-2"></i>
                  <span>Team Management</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="fas fa-trash-alt" />
                  <span>Recycle Bin</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
