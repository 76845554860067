import AutocompleteInput from "components/AutocompleteInput";
import Creatable from "react-select/creatable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Spinner } from "reactstrap";
import { addDocument } from "store/actions";
import { editDocument } from "store/actions";

const SiteModal = ({ toggle, edit, add, siteId, siteName, clientName, location, imageUrl }) => {

    const { sites, btnLoading } = useSelector((state) => state.sites);

    const [selectedClient, setSelectedClient] = useState([]);
    const [error, setError] = useState();

    const [sitePayload, setSitePayload] = useState({
        userId: "6xBLaLMMMppbwqknIbGu",
        siteName: "",
        location: '',
        clientName: "",
        createdBy: "Jessica Jones",
        image: null,
    });
    console.log("sitePayload: ", sitePayload);

    const uniqueClientNames = [...new Set(sites.map(site => site.clientName))];
    const options = uniqueClientNames.map(clientName => ({
        label: clientName,
        value: clientName
    }));

    const dispatch = useDispatch();

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setSitePayload((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleImage = (e) => {
        let file = e.target.files[0];
        setSitePayload((prevState) => ({
            ...prevState,
            image: { file, previewPic: URL.createObjectURL(file) },
        }));
    };

    const handleNewCreatable = (newValue) => {
        setSelectedClient({ label: newValue, value: newValue });
        setSitePayload((prevState) => ({
            ...prevState,
            clientName: newValue,
        }));
    };

    const handleChangeCreatable = (newValue) => {
        setSelectedClient(newValue);
        setSitePayload((prevState) => ({
            ...prevState,
            clientName: newValue.value,
        }));
    };

    const handleSubmit = async () => {
        let error = "";
        if (sitePayload.siteName === "") {
            error = "Site Name is required";
        } else if (sitePayload.clientName === "") {
            error = "Client Name is required";
        } else if (sitePayload.location === "") {
            error = "Location is required";
        } else {
            try {
                const res = add
                    ? await dispatch(
                        addDocument({
                            collectionName: "pf_sites",
                            payload: sitePayload
                        })
                    )
                    : await dispatch(
                        editDocument({
                            collectionName: "pf_sites",
                            id: siteId,
                            payload: sitePayload,
                        })
                    );
                    console.log('res: ', res);

                if (
                    res?.type === "add/document/fulfilled" ||
                    "edit/document/fulfilled"
                ) {
                    toggle();
                    setSitePayload({
                        siteName: "",
                        clientName: "",
                        image: null,
                    });
                } else {
                    console.log("response not fullfilled", res?.payload);
                }
                return;
            } catch (error) {
                console.log("Cannot submit", error);
                return;
            }
        }
        setError(error);
        setTimeout(() => {
            setError("");
        }, 2000);
    };

    useEffect(() => {
        if (edit) {
            setSitePayload((prevSitePayload) => ({
                ...prevSitePayload,
                siteName: siteName,
                clientName: clientName,
                location: location,
                image: { previewPic: imageUrl || "" }
            }));
            setSelectedClient({ label: clientName, value: clientName });
        }
    }, []);

    return (
        <div className=" d-flex flex-column p-3">
            <h2 className="text-primary font-weight-bolder">
                {add ? "New Site" : "Edit Site"}
            </h2>

            <Input
                className="mb-3 border-secondary-subtle"
                type="text"
                placeholder="Enter Site Name"
                name="siteName"
                value={sitePayload.siteName}
                onChange={handleInputs}
                required
            />
            <div>
                <AutocompleteInput
                    sitePayload={sitePayload}
                    setSitePayload={setSitePayload}
                    handleInputs={handleInputs}
                />
            </div>

            <Creatable
                classNames={{
                    control: (state) =>
                        state.isFocused ? 'border-transparent' : 'border-light',
                }}
                className="mb-3 text-sm "
                value={selectedClient}
                onChange={handleChangeCreatable}
                name="clientName"
                placeholder="Enter Client Name"
                options={options}
                onCreateOption={handleNewCreatable}
            />

            <div className="d-flex flex-column align-self-start mb-3 border border-light rounded">
                <div>
                    {sitePayload.image?.previewPic && (
                        <img
                            src={sitePayload.image?.previewPic}
                            alt="Picture"
                            height={150}
                            className="w-100"
                        />
                    )}
                </div>
                <label
                    className={`d-flex align-items-center p-1 m-0 ${sitePayload.image?.previewPic && 'border-top border-light'}`}
                    htmlFor="imageInput"
                    role="button"
                >
                    <i className="ni ni-image text-xl mr-2" />
                    Attach Image
                </label>
                <Input
                    className="d-none"
                    id="imageInput"
                    type="file"
                    accept=".jpg , .jpeg ,.png, .svg"
                    onChange={handleImage}
                />
            </div>
            {error && (
                <p className="text-danger text-sm m-0 pl-1 font-weight-bold">{error}</p>
            )}

            <Button color="primary" onClick={handleSubmit} disabled={btnLoading}>
                {btnLoading ? <Spinner color="light" /> : add ? "CREATE" : "EDIT"}
            </Button>
        </div>
    );
};
export default SiteModal;
