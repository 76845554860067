import { Button, Input } from "reactstrap"

const AddFile = () => {
    return (
        <div className=' d-flex flex-column p-3'>
            <h2 className='text-primary font-weight-bolder'>
                Add Files
            </h2>

            <Input
                className='mb-3'
                type="file"
            />

            <Button color='primary'>ADD</Button>
        </div>
    )
}
export default AddFile