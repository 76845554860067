import { createSlice } from '@reduxjs/toolkit';

const SidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        toggleSidebar: false,
    },
    reducers: {
        toggleSidebarAction: (state, action) => {
            state.toggleSidebar = action.payload;
        },
    },
});

export const { toggleSidebarAction } = SidebarSlice.actions;
export default SidebarSlice;
