import { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types'; // nodejs library to set properties for components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Progress,
	Table,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebarAction } from 'store/slices/SidebarSlice';
import avatar from '../../assets/img/theme/team-3-800x800.jpg'
var ps;

const Sidebar = props => {

	const dispatch = useDispatch()

	const { toggleSidebar } = useSelector(state => state.sidebar)

	const [collapseOpen, setCollapseOpen] = useState();
	// verifies if routeName is the one active (in browser input)
	const activeRoute = routeName => {
		return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';

	};
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen(data => !data);
		dispatch(toggleSidebarAction(!toggleSidebar));
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
		dispatch(toggleSidebarAction(false));
	};

	// creates the links that appear in the left menu / Sidebar
	const createLinks = routes => {
		return routes.map((prop, key) => {
			if (prop.isMenu != false)
				return (
					<NavItem key={key}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={closeCollapse}
							activeClassName='active'
							id={prop.name}
						>
							<i className={`${prop.icon} text-white`} />

							<a className='text-nowrap ml-2 text-white'>{toggleSidebar && prop.name}</a>
						</NavLink>
						{
							!toggleSidebar &&
							<UncontrolledTooltip
								delay={0}
								target={prop.name}
							>
								{prop.name}
							</UncontrolledTooltip>
						}
					</NavItem>
				);
		});
	};

	// -----------------------------------------------------
	const { bgColor, routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}

	const handleToggle = () => {
		dispatch(toggleSidebarAction(!toggleSidebar))
	}

	return (
		<Navbar
			className={toggleSidebar ? 'navbar-vertical fixed-left navbar-dark bg-primary w-100' : 'navbar-vertical fixed-left navbar-dark bg-primary'}
			expand='md'
			id='sidenav-main'
		>
			<Container fluid>
				{/* Toggler */}
				<button
					className='navbar-toggler'
					type='button'
					onClick={toggleCollapse}
				>
					<span className='navbar-toggler-icon' />
				</button>
				{/* Brand */}
				{logo ? (
					<NavbarBrand className='p-0' {...navbarBrandProps}>
						<i className='ni ni-bell-55 text-white text-center p-2 my-2 border rounded' />
					</NavbarBrand>

				) : null}

				{/* User */}
				<Nav className='align-items-center d-md-none'>
					<UncontrolledDropdown nav>
						<DropdownToggle className="pr-0" nav>
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle">
									<img
										alt="..."
										src={avatar}
									/>
								</span>
								<Media className="ml-2 d-none d-lg-block">
									<span className="mb-0 text-sm font-weight-bold">
										Jessica Jones
									</span>
								</Media>
							</Media>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem className="noti-title" header tag="div">
								<h6 className="text-overflow m-0">Welcome!</h6>
							</DropdownItem>
							<DropdownItem to="/admin/user-profile" tag={Link}>
								<i className="ni ni-single-02" />
								<span>My profile</span>
							</DropdownItem>
							<DropdownItem to="/admin/user-profile" tag={Link}>
								<i className="ni ni-single-02" />
								<span>Team Management</span>
							</DropdownItem>
							<DropdownItem to="/admin/user-profile" tag={Link}>
								<i className="fas fa-trash-alt" />
								<span>Recycle Bin</span>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
								<i className="fas fa-sign-out-alt"></i>
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className='navbar-collapse-header d-md-none'>
						<Row>
							{logo ? (
								<Col className='collapse-brand' xs='6'>
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</a>
									)}
								</Col>
							) : null}
							<Col className='collapse-close' xs='6'>
								<button
									className='navbar-toggler'
									type='button'
									onClick={closeCollapse}
								>
									<span />
									<span />
								</button>
							</Col>

						</Row>
					</div>
					{/* Form */}
					{/* <Form className='mt-4 mb-3 d-md-none'>
						<InputGroup className='input-group-rounded input-group-merge'>
							<Input
								aria-label='Search'
								className='form-control-rounded form-control-prepended'
								placeholder='Search'
								type='search'
							/>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText>
									<span className='fa fa-search' />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Form> */}
					{/* Navigation */}
					<Nav navbar>{createLinks(routes)}</Nav>
				</Collapse>
				
				<i className={`${toggleSidebar && 'fa-rotate-180'} fas fa-sign-in-alt text-white text-xl my-2 d-none d-md-block align-self-center`}
					role="button"
					onClick={handleToggle}
				/>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};
export default Sidebar;
