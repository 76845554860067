import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const CardsDropdown = ({ siteId, projectId, toggle, handleDuplicate, handleNavigate, handleDelete, s_card, p_card, m_card }) => {
    return (
        <UncontrolledDropdown nav>

            <DropdownToggle nav className='p-0'>
                <i className="fa fa-ellipsis-v text-light text-xl"></i>
            </DropdownToggle>

            <DropdownMenu
                className='dropdown-menu-arrow'
                right
            >
                <DropdownItem
                    className='d-flex align-items-center'
                    onClick={toggle}
                >
                    <i className="fas fa-edit" />
                    Edit
                </DropdownItem>

                <DropdownItem
                    className='d-flex align-items-center'
                    onClick={handleDuplicate}
                >
                    <i className="fas fa-copy" />
                    Duplicate
                </DropdownItem>

                {!m_card &&
                    <DropdownItem
                        className='d-flex align-items-center'
                        onClick={() => {
                            s_card ?
                                handleNavigate(siteId) :
                                handleNavigate(siteId, projectId)
                        }}
                    >
                        <i className="fas fa-desktop" />
                        {s_card ?
                            'View all Projects' :
                            'Project Details'
                        }
                    </DropdownItem>
                }

                <DropdownItem divider />

                <DropdownItem
                    className='d-flex align-items-center text-danger'
                    onClick={handleDelete}
                >
                    <i className="fas fa-trash-alt" />
                    Delete
                </DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
export default CardsDropdown