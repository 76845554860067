import { useState } from 'react'
import { Badge, Card, Modal } from 'reactstrap'
import DetailBox from './DetailBox'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import EmailAddress from 'components/Modals/EmailAddress'
import img from '../../assets/img/bicycle.png'
import { deleteDocument } from 'store/actions'
import { useDispatch } from 'react-redux'
import { duplicateDocument } from 'store/actions'
import ProjectModal from 'components/Modals/ProjectModal'
import CardsDropdown from './CardsDropdown'
import { projectPrivacy } from 'store/actions'

const ProjectCard = ({ data, subtitle }) => {

    const [duplicatePayload, setDuplicatePayload] = useState({
        userId: data.userId,
        siteId: data.siteId,
        projectName: data.projectName,
        clientName: data.clientName,
        createdBy: data.createdBy,
        share: data.share,
        tags: data.tags,
        status: data.status,
        imageUrl: data.imageUrl || null,
        locked: true,
        createdAt: '',
        projectId: '',
    })

    const [emailmodal, setEmailModal] = useState(false);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const toggleEmailModal = () => setEmailModal(!emailmodal);

    const history = useHistory()

    const dispatch = useDispatch()

    const handleNavigate = (siteId, projectId) => {
        history.push(`/main/projects/${siteId}/${projectId}`)
    }

    const handleDelete = () => {
        dispatch(deleteDocument({
            collectionName: 'pf_projects',
            id: data.projectId
        }))
    }

    const handleDuplicate = () => {
        dispatch(duplicateDocument({
            collectionName: 'pf_projects',
            payload: duplicatePayload
        }))
    }

    const handleTogglePrivacy = () => {
        dispatch(projectPrivacy({
            collectionName: 'pf_projects',
            id: data.projectId,
            payload: { locked: !data.locked }
        }))
    }

    const handleCopyLink = () => {
        alert('link Copied')
        navigator.clipboard.writeText(`http://localhost:3000/main/projects/${data.siteId}/${data.projectId}`)
    }

    return (
        <div className='mt-5'>
            <Card className="borderRadius pt-5 pb-3 position-relative">
                {/* ------------------------------Icon Card--------------------- */}
                <div className='icon-card'>
                    <img
                        src={data.imageUrl == null ? img : data.imageUrl}
                        alt='icon'
                    />
                </div>

                {/* ------------------------------Dropdown--------------------- */}
                <div className='position-absolute top-4 right-3'>
                    {!data.locked &&
                        <CardsDropdown
                            siteId={data.siteId}
                            projectId={data.projectId}
                            toggle={toggle}
                            handleDuplicate={handleDuplicate}
                            handleNavigate={handleNavigate}
                            handleDelete={handleDelete}
                            p_card
                        />
                    }
                </div>

                {/* ------------------------------Lock&Share--------------------- */}
                <div className='d-flex align-items-center' role='button'>
                    <i className={`${data.locked ? 'fa fa-lock text-yellow' : 'fa fa-unlock text-blue'} ml-2 mr-1 text-lg`} onClick={handleTogglePrivacy} />

                    <i className="ni ni-curved-next text-green mr-1 text-lg" />

                    <i className="fa fa-link text-info mr-1 text-lg" onClick={handleCopyLink} />

                    <i className='ni ni-bold mr-1' />

                    <i className="ni ni-email-83 text-warning mr-1 text-lg" onClick={toggleEmailModal} />

                    <a href='https://discord.com/'>
                        <i className="fab fa-discord text-blue mr-1 text-lg" />
                    </a>
                </div>

                {/* ------------------------------Heading--------------------- */}
                <div className="col d-flex flex-column align-items-center p-1">

                    <div className='d-flex flex-column text-center'>
                        <h2 className="text-primary font-weight-bolder mb-0">
                            {data.projectName}
                        </h2>
                        <h4 className="text-muted mb-0">
                            {subtitle}
                        </h4>
                    </div>

                    {/* ------------------------------DetailBox--------------------- */}
                    <div className='w-100'>
                        <DetailBox share={data.share} />
                    </div>
                </div>

                <div className='d-flex align-items-center'>
                    <i className="fas fa-tag ml-3 text-lg" />
                    {data?.tags?.map((items) => {
                        return (
                            <div className='mx-1 text-xs'>
                                <Badge color="primary">{items}</Badge>
                            </div>
                        )
                    })}
                </div>
            </Card>

            <Modal isOpen={emailmodal} toggle={toggleEmailModal} centered>
                <EmailAddress />
            </Modal>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ProjectModal
                    toggle={toggle}
                    siteId={data.siteId}
                    projectId={data.projectId}
                    projectName={data.projectName}
                    imageUrl={data.imageUrl}
                    tags={data.tags}
                    edit
                />
            </Modal>
        </div>
    )
}
export default ProjectCard