import { useState } from 'react';
import { Card, Button, Modal, Input, ButtonGroup } from 'reactstrap';
import AddFile from 'components/Modals/AddFile';

const files = [
    {
        id: 1,
        name: 'Project Files',
    },
    {
        id: 2,
        name: 'Balance Sheet',
    },
    {
        id: 3,
        name: 'Remaining balance',
    },
    {
        id: 4,
        name: 'Important notes',
    },
    {
        id: 5,
        name: 'Plans',
    },
    {
        id: 6,
        name: 'Read docs',
    },
]

const FilesTab = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <div className='d-flex'>
                <h1 className='text-primary m-0'>Files</h1>
                <div className='d-flex align-items-center'>
                    <ButtonGroup className='mx-3' size="sm">
                        <Button
                            color="primary"
                            outline
                            onClick={() => { }}
                        >
                            Delete
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => { }}
                        >
                            Download
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className='d-flex justify-content-end mb-3'>
                <Button
                    color='primary'
                    onClick={toggle}
                >
                    Add
                </Button>
            </div>

            {files.map((data) => {
                return (
                    <Card className='p-2 mb-2' key={data.id}>
                        <div className='d-flex align-items-center ml-4'>
                            <Input
                                type='checkbox'
                            />
                            <h3 className='m-0'>{data.name}</h3>
                        </div>
                    </Card>
                )
            })}
            <Modal isOpen={modal} toggle={toggle} centered>
                <AddFile />
            </Modal>
        </div>
    )
}

export default FilesTab;
