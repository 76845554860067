import { createAsyncThunk } from '@reduxjs/toolkit';
import { collection, addDoc, getDocs, serverTimestamp, updateDoc, query, where, doc, deleteDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { db, storage } from '../../src/config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { handleAllProjects, handleMilestones, handleProjects, handleSites } from './slices/SiteSlice';

// Fetch Sites
export const fetchSites = createAsyncThunk(
    'fetch/sites',
    ({ userId, field, order }, { dispatch, rejectWithValue }) => {
        try {
            const q = query(collection(db, 'pf_sites'), orderBy(field, order), where("userId", "==", userId));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const sitesList = querySnapshot.docs.map(doc => doc.data());
                dispatch(handleSites(sitesList));
            });
        } catch (error) {
            console.error("Error fetching sites:", error);
            return rejectWithValue(error);
        }
    }
);

//Fetch AllProjects
export const fetchAllProjects = createAsyncThunk(
    'fetchAll/projects',
    async ({ field, order }, { dispatch, rejectWithValue }) => {
        try {
            const q = query(collection(db, 'pf_projects'), orderBy(field, order));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const projectsList = querySnapshot.docs.map(doc => doc.data());
                dispatch(handleAllProjects(projectsList));
            });
        } catch (error) {
            console.error("Error fetching projects:", error);
            return rejectWithValue(error);
        }
    }
);

// Fetch Selected Site Projects
export const fetchProjects = createAsyncThunk(
    'fetch/projects',
    async ({ id, field, order }, { dispatch, rejectWithValue }) => {
        try {
            const q = query(collection(db, 'pf_projects'), orderBy(field, order), where('siteId', "==", id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const selectedProject = querySnapshot.docs.map(doc => doc.data());
                dispatch(handleProjects(selectedProject));
                dispatch(handleAllProjects(selectedProject));
            });
        } catch (error) {
            console.error("Error fetching projects:", error);
            return rejectWithValue(error);
        }
    }
);

//Fetch Single Projects
export const fetchSingleProject = createAsyncThunk(
    'fetch/singleProject',
    async (projectId, { rejectWithValue }) => {
        try {
            const q = query(collection(db, 'pf_projects'), where("projectId", "==", projectId));
            const projectSnapshot = await getDocs(q);
            const singleproject = projectSnapshot.docs.map(doc => doc.data());
            return singleproject;
        } catch (error) {
            console.log('Failed to fetch singleProject.', error);
            return rejectWithValue('Failed to fetch singleProject');
        }
    }
);

// Fetch Milestones
export const fetchMilestones = createAsyncThunk(
    'fetch/milestones',
    async (projectId, { dispatch, rejectWithValue }) => {
        try {
            const q = query(collection(db, 'pf_milestones'), orderBy("createdAt"), where("projectId", "==", projectId));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const milestonesList = querySnapshot.docs.map(doc => doc.data());
                dispatch(handleMilestones(milestonesList));
            });
        } catch (error) {
            console.log('Failed to fetch milestones', error);
            return rejectWithValue('Failed to fetch milestones', error);
        }
    }
);

//Add
export const addDocument = createAsyncThunk(
    'add/document',
    async ({ collectionName, payload }, { rejectWithValue }) => {
        try {
            let finalPayload;
            const { image, ...data } = payload
            if (image && image.file) {
                let imageUrl = null;
                const storageRef = ref(storage, `images/${image.file.name}`);
                await uploadBytes(storageRef, image.file);
                imageUrl = await getDownloadURL(storageRef);
                finalPayload = {
                    ...data,
                    imageUrl: imageUrl,
                    createdAt: serverTimestamp()
                };
            } else {
                finalPayload = {
                    ...data,
                    createdAt: serverTimestamp()
                };
            }
            const collectionRef = collection(db, collectionName);
            const docRef = await addDoc(collectionRef, finalPayload);
            let updateData = {};
            if (collectionName === 'pf_sites') {
                updateData = { siteId: docRef.id };
            } else if (collectionName === 'pf_projects') {
                updateData = { projectId: docRef.id };
            } else if (collectionName === 'pf_milestones') {
                updateData = { milestoneId: docRef.id };
            }
            await updateDoc(docRef, updateData);
            console.log('added successfully!', finalPayload);
            return finalPayload;
        } catch (error) {
            console.log('error while adding', error);
            return rejectWithValue('error while adding', error);
        }
    }
);

//EDIT
export const editDocument = createAsyncThunk(
    'edit/document',
    async ({ collectionName, id, payload }, { rejectWithValue }) => {
        try {
            let finalPayload;
            const { image, ...data } = payload
            if (image && image.file) {
                let imageUrl = null;
                const storageRef = ref(storage, `images/${image.file.name}`);
                await uploadBytes(storageRef, image.file);
                imageUrl = await getDownloadURL(storageRef);
                finalPayload = {
                    ...data,
                    imageUrl: imageUrl,
                };
            } else {
                finalPayload = {
                    ...data,
                };
            }
            const collectionRef = doc(db, collectionName, id);
            await updateDoc(collectionRef, finalPayload);

            console.log('edited successfully', finalPayload);
            return finalPayload;
        } catch (error) {
            console.log('error while editing', error);
            return rejectWithValue(error.message);
        }
    }
);

// Delete
export const deleteDocument = createAsyncThunk(
    'delete/document',
    async ({ collectionName, id }, { rejectWithValue }) => {
        try {
            const collectionRef = doc(db, collectionName, id);
            await deleteDoc(collectionRef);
            console.log('deleted successfully!');
            if (collectionName === 'pf_sites') {
                // Delete projects with the same siteId
                const projectQuerySnapshot = await getDocs(
                    query(collection(db, 'pf_projects'), where('siteId', '==', id))
                );
                const deleteProjectPromises = projectQuerySnapshot.docs.map(projectDoc => deleteDoc(projectDoc.ref));
                await Promise.all(deleteProjectPromises);

                // Delete milestones with the same siteId
                const milestoneQuerySnapshot = await getDocs(
                    query(collection(db, 'pf_milestones'), where('siteId', '==', id))
                );
                const deleteMilestonePromises = milestoneQuerySnapshot.docs.map(milestoneDoc => deleteDoc(milestoneDoc.ref));
                await Promise.all(deleteMilestonePromises);
            } else if (collectionName === 'pf_projects') {
                // Delete milestones with the same projectId
                const milestoneQuerySnapshot = await getDocs(
                    query(collection(db, 'pf_milestones'), where('projectId', '==', id))
                );
                const deleteMilestonePromises = milestoneQuerySnapshot.docs.map(milestoneDoc => deleteDoc(milestoneDoc.ref));
                await Promise.all(deleteMilestonePromises);
            }
        } catch (error) {
            console.log('error while deleting:', error);
            return rejectWithValue('error deleting document:', error);
        }
    }
);

// Duplicate
export const duplicateDocument = createAsyncThunk(
    'duplicate/document',
    async ({ collectionName, payload }, { rejectWithValue }) => {
        try {
            const collectionRef = collection(db, collectionName);
            const docRef = await addDoc(collectionRef, payload);
            let updateData = {};
            if (collectionName === 'pf_sites') {
                updateData = { siteId: docRef.id, createdAt: serverTimestamp() };
            }
            else if (collectionName === 'pf_projects') {
                updateData = { projectId: docRef.id, createdAt: serverTimestamp() };
            }
            else if (collectionName === 'pf_milestones') {
                updateData = { milestoneId: docRef.id, createdAt: serverTimestamp() };
            }
            await updateDoc(docRef, updateData);

            console.log('duplicated successfully', payload);
            return payload;
        } catch (error) {
            console.log('error while duplicating:', error);
            return rejectWithValue('error while duplicating:', error);
        }
    }
);

//Fitered Projects
export const fetchFilteredProjects = createAsyncThunk(
    'fetch/filteredprojects',
    async ({ projectName, clientName, tags, status }, { dispatch, rejectWithValue }) => {
        try {
            let q = collection(db, 'pf_projects');
            if (projectName) {
                q = query(q, where('projectName', '==', projectName));
            }
            if (clientName) {
                q = query(q, where('clientName', '==', clientName));
            }
            if (tags && tags.length > 0) {
                q = query(q, where('tags', 'array-contains-any', tags));
            }
            if (status) {
                q = query(q, where('status', '==', status));
            }
            const snapshot = await getDocs(q);
            const projects = snapshot.docs.map(doc => doc.data());
            dispatch(handleAllProjects(projects));
            return projects;
        } catch (error) {
            console.error("Error fetching projects:", error);
            return rejectWithValue(error);
        }
    }
);

export const fetchFilteredSiteProjects = createAsyncThunk(
    'fetch/filteredsiteProjects',
    async ({ siteId, ...filterPayload }, { dispatch, rejectWithValue }) => {
        try {
            let q = collection(db, 'pf_projects');
            if (filterPayload.projectName) {
                q = query(q, where('siteId', '==', siteId), where('projectName', '==', filterPayload.projectName));
            }
            if (filterPayload.clientName) {
                q = query(q, where('siteId', '==', siteId), where('clientName', '==', filterPayload.clientName));
            }
            if (filterPayload.tags && filterPayload.tags.length > 0) {
                q = query(q, where('siteId', '==', siteId), where('tags', 'array-contains-any', filterPayload.tags));
            }
            if (filterPayload.status) {
                q = query(q, where('siteId', '==', siteId), where('status', '==', filterPayload.status));
            }
            const snapshot = await getDocs(q);
            const projects = snapshot.docs.map(doc => doc.data());
            dispatch(handleProjects(projects));
            return projects;
        } catch (error) {
            console.error("Error fetching projects:", error);
            return rejectWithValue(error);
        }
    }
);
//Project Privacy
export const projectPrivacy = createAsyncThunk(
    'privacy/project',
    async ({ collectionName, id, payload }, { rejectWithValue }) => {
        try {
            const collectionRef = doc(db, collectionName, id);
            await updateDoc(collectionRef, payload);

            console.log('edited locked successfully', payload);
            // return payload;
        } catch (error) {
            console.log('error while editing locked', error);
            return rejectWithValue(error.message);
        }
    }
);