import MilestoneCard from 'components/Cards/MilestoneCard';
import MilestoneModal from 'components/Modals/MilestoneModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Button, Modal, Spinner } from 'reactstrap';
import { fetchMilestones } from 'store/actions';

const MilestonesTab = () => {

    const { milestones, isLoading } = useSelector((state) => state.sites)
    console.log('GET/Milestones: ', milestones);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const { projectId } = useParams();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchMilestones(projectId))
    }, [])

    return (
        <div>
            <h1 className='text-primary'>Milestones</h1>

            <div className='d-flex justify-content-end'>

                <Button onClick={toggle} color='primary'>Add</Button>
            </div>

            <div>
                {isLoading || !milestones ?
                    <div className='spinner'>
                        <Spinner className='mx-1' color="primary" type="grow" />
                        <Spinner className='mx-1' color="primary" type="grow" />
                        <Spinner className='mx-1' color="primary" type="grow" />
                    </div>
                    :
                    <Row xl={4} lg={3} md={2} sm={1} xs={1}>

                        {(milestones.length === 0) ?
                            <h2 className="m-3">No Milestones set. <br />Please create one!</h2>
                            :
                            milestones?.map((data) => {
                                return (
                                    <Col className=" my-3" key={data.milestoneId}>
                                        <MilestoneCard data={data} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
            </div>
            <Modal isOpen={modal} toggle={toggle} centered>
                <MilestoneModal toggle={toggle} add />
            </Modal>
        </div>
    )
}
export default MilestonesTab