import { useState, useEffect } from 'react';
import DiscussionsTab from 'components/Project/DiscussionsTab';
import FilesTab from 'components/Project/FilesTab';
import GanttChartTab from 'components/Project/GanttChartTab';
import MilestonesTab from 'components/Project/MilestonesTab';
import OverviewTab from 'components/Project/OverviewTab';
import TaskListsTab from 'components/Project/TaskListsTab';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleProject } from 'store/actions';

const ProjectDetail = () => {

    const { singleProject } = useSelector((state) => state.sites)
    console.log('GET/singleProject: ', singleProject);

    const { projectId } = useParams();

    const [activeTab, setActiveTab] = useState("1");
    const changeTab = (tabId) => setActiveTab(tabId);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch((fetchSingleProject(projectId)))
    }, [])

    return (
        <div>
            <Card className='px-2 pt-3'>
                <Nav className='text-nowrap mb-0' pills justified >
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => changeTab("1")}
                        >
                            Overview
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => changeTab("2")}
                        >
                            Task Lists
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => changeTab("3")}
                        >
                            Discussions
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => changeTab("4")}
                        >
                            Milestones
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => changeTab("5")}
                        >
                            Files
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "6" ? "active" : ""}
                            onClick={() => changeTab("6")}
                        >
                            Gantt Chart
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "7" ? "active" : ""}
                            onClick={() => changeTab("7")}
                        >
                            Network
                        </NavLink>
                    </NavItem>

                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "8" ? "active" : ""}
                            onClick={() => changeTab("8")}
                        >
                            Floor Plan
                        </NavLink>
                    </NavItem>
                    <NavItem role='button' className='px-2 mb-3'>
                        <NavLink
                            className={activeTab === "9" ? "active" : ""}
                            onClick={() => changeTab("9")}
                        >
                            Energy
                        </NavLink>
                    </NavItem>
                </Nav>
            </Card>

            <br />

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card body>
                        <OverviewTab project={singleProject} />
                    </Card>
                </TabPane>

                <TabPane tabId="2">
                    <Card body>
                        <TaskListsTab />
                    </Card>
                </TabPane>

                <TabPane tabId="3">
                    <Card body>
                        <DiscussionsTab />
                    </Card>
                </TabPane>

                <TabPane tabId="4">
                    <Card body>
                        <MilestonesTab />
                    </Card>
                </TabPane>

                <TabPane tabId="5">
                    <Card body>
                        <FilesTab />
                    </Card>
                </TabPane>

                <TabPane tabId="6">
                    <Card body>
                        <GanttChartTab />
                    </Card>
                </TabPane>
                <TabPane tabId="7">
                    <Card body>
                        Network TAB
                    </Card>
                </TabPane>
                <TabPane tabId="8">
                    <Card body>
                        Floor Plan TAB
                    </Card>
                </TabPane>
                <TabPane tabId="9">
                    <Card body>
                        Energy TAB
                    </Card>
                </TabPane>
            </TabContent >
        </div >
    );
};
export default ProjectDetail;
