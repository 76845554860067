import { useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ThreeD from './ThreeD';

const OverviewTab = ({ project }) => {

    const [activeTab, setActiveTab] = useState("1");

    const changeTab = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <div>
            <h1 className='text-primary'>Overview</h1>
            <Card body>
                {project &&
                    <div>
                        <div className='d-flex flex-wrap'>
                            <h4 className='mr-2'>
                                Project Name:
                            </h4>
                            <span>
                                {project[0]?.projectName}
                            </span>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <h4 className='mr-3'>
                                Client Name:
                            </h4>
                            <span>
                                {project[0]?.clientName}
                            </span>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <h4 className='mr-4'>
                                Created At:
                            </h4>
                            <span>
                                {project[0]?.createdAt.toDate().toDateString()}
                            </span>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <h4 className='mr-4'>
                                Created By:
                            </h4>
                            <span>
                                {project[0]?.createdBy}
                            </span>
                        </div>
                    </div>
                }
                <br />

                <div>
                    <Nav tabs>
                        <NavItem role='button'>
                            <NavLink
                                className={activeTab === "1" ? "active text-blue font-weight-bolder" : "font-weight-bolder"}
                                onClick={() => changeTab("1")}
                            >
                                Whiteboard
                            </NavLink>
                        </NavItem>
                        <NavItem role='button'>
                            <NavLink
                                className={activeTab === "2" ? "active text-blue font-weight-bolder" : "font-weight-bolder"}
                                onClick={() => changeTab("2")}
                            >
                                2D Floorplan
                            </NavLink>
                        </NavItem>
                        <NavItem role='button'>
                            <NavLink
                                className={activeTab === "3" ? "active text-blue font-weight-bolder" : "font-weight-bolder"}
                                onClick={() => changeTab("3")}
                            >
                                3D Floorplan
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className='border'>
                        <TabPane tabId="1">
                            <iframe width="100%" height="800" className='p-1'
                                src="https://miro.com/app/live-embed/uXjVN0SRiQo=/?moveToViewport=-560332,44408,45572,21623&embedId=25299341787"
                                allow="fullscreen; clipboard-read; clipboard-write">
                            </iframe>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className='d-flex justify-content-end'>
                                <i className="fas fa-cog text-xl p-1" role='button' />
                            </div>
                            <iframe width="100%" height="800px" className='p-1'
                                src="https://threejs.org/editor/" >
                            </iframe>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className='m-1'>
                                <ThreeD />
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </Card >
        </div >
    )
}
export default OverviewTab